/* :root {
  --primary-color: #1e90ff;
  --white: #ffffff;
} */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

@tailwind base;
@tailwind components;

@import 'additional-styles/custom-styles.scss';
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
// @import 'additional-styles/flatpickr.scss';
// @import 'additional-styles/theme.scss';

@tailwind utilities;
