.h1 {
  @apply text-[50px]  leading-[73px]  tracking-tighter font-title_font;
}

.h2 {
  @apply text-[40px]  leading-[60px]  tracking-tighter font-title_font;
}

.h3 {
  @apply text-[30px]  leading-[49px] font-title_font;
}

.h4 {
  @apply text-[20px]  leading-[37px] tracking-tight font-title_font;
}

.h5 {
  @apply text-[20px]  leading-[29px] font-title_font;
}

.h6 {
  @apply text-[18px]  leading-[24px] font-title_font;
}

.body {
  @apply text-[16px]  leading-[20px] font-body_font;
}
.paragraph {
  @apply text-[16px]  leading-[29px] tracking-tight font-body_font;
}
.caption {
  @apply text-[14px]  leading-[17px] font-body_font;
}
.small {
  @apply text-[12px]  leading-[15px] font-body_font;
}
.tiny {
  @apply text-[10px]  leading-[12px] font-body_font;
}

@screen md {
  .h1 {
    @apply text-[60px]  leading-[73px] font-title_font;
  }

  .h2 {
    @apply text-[50px]  leading-[60px] font-title_font;
  }
  .h3 {
    @apply text-[40px]  leading-[49px] font-title_font;
  }
  .h4 {
    @apply text-[30px]  leading-[37px] font-title_font;
  }
  .h5 {
    @apply text-[24px]  leading-[29px] font-title_font;
  }
  .h6 {
    @apply text-[20px]  leading-[24px] font-title_font;
  }
  .paragraph {
    @apply text-[20px]  leading-[29px] tracking-tight font-body_font;
  }
  .body {
    @apply text-[16px]  leading-[20px] font-body_font;
  }
}

// Tradly Buttons

.btn,
.btn-lg,
.btn-sm,
.btn-xs {
  @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded-button leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn {
  @apply px-3 py-2;
}

.btn-lg {
  @apply px-5 py-3;
}

.btn-sm {
  @apply px-4 py-2;
}

.btn-xs {
  @apply px-2 py-0.5;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply text-sm text-gray-800 bg-white border;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply leading-5 py-2 px-3 border-gray-200 hover:border-gray-300 focus:border-indigo-300 shadow-sm;
}

.form-input,
.form-textarea {
  @apply placeholder-gray-400;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-primary border border-gray-300;
}
