* {
  font-family: var(--primary_font);
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: var(--primary_color) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* OTP Box */
.otp-input input {
  height: 100%;
  width: 100%;
  background: transparent;
  color: white !important;
  font-size: 20px !important;
  font-weight: 600;
  width: 100% !important;
  margin: 0px !important;
  outline: none;
  border: 1px solid white;
  border-radius: 48px !important;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.otp-input input:focus {
  outline: none;
  border: 1px solid white;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.swiper-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@keyframes ldio-uqpdw6hds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-uqpdw6hds div {
  position: absolute;
  animation: ldio-uqpdw6hds linear 1s infinite;
  background: var(--primary_color);
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}
.ldio-uqpdw6hds div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: var(--primary_color);
}
.ldio-uqpdw6hds div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: var(--primary_color);
}
.loadingio-spinner-spinner-xedb3iwiaw {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-uqpdw6hds {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-uqpdw6hds div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

.form-control {
  background-color: transparent !important;
  width: 100% !important;
  border: none !important;
  height: 48px !important;
  border-radius: 48px !important;
  border: 1px solid white !important;
  padding-left: 55px !important;
}
.form-control:focus {
  outline: none !important;
}

.flag-dropdown {
  border: none !important;
  background-color: transparent !important;
  padding-left: 12px !important;
}
.react-tel-input .country-list {
  background-color: var(--primary_color) !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #f1ecec !important;
  color: var(--primary_color) !important;
}

.rc-slider-track {
  background-color: var(--primary_color) !important;
}
.rc-slider-handle {
  border-color: var(--primary_color) !important;
}
.rc-slider-handle:focus {
  border-color: var(--primary_color) !important;
}
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none;
}

.dropdown-container {
  border: none !important;
  border-bottom: 2px solid rgba(229, 231, 235) !important;
  border-radius: 0px !important;
  transition: all 700ms ease-in-out;
}
.filter_multi_select > .dropdown-container {
  border:2px solid rgba(229, 231, 235) !important;
   border-radius: 0px !important;
  transition: all 700ms ease-in-out;
}

.dropdown-container:focus-within {
  border-color: var(--primary_color) !important;
  box-shadow: none !important;
}

.editor {
  .ProseMirror {
    min-height: 180px;
  }
}

.ProseMirror {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0 !important;
  }
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
  left: -25px !important;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
